import React from 'react';
import EntitySelectItem from './EntitySelectItem';
import { useScrollThreshold } from '../../../Helpers/effects';
import InputText from '../../InputText/InputText';
import { useTextInputWithDelay } from '../../../Helpers/FormsHelpers/hooks';

type Props<T> = {
    className?: string;
    values: string[] | undefined | null;
    placeholder: string;
    options: string[];
    useItem: (id: string | undefined) => T | null;
    onSelect: (entityId: string) => void;
    getMoreResults: () => void;
    getEntityName: (entity: T) => string;
    search?: (query: string) => void;
    noSearchDelay?: boolean;
};

type EntitySelect<T> = React.FC<Props<T>>;

const EntitySelectInline = <T,>({
    values,
    placeholder,
    options,
    useItem,
    onSelect,
    getMoreResults,
    getEntityName,
    className,
    search,
    noSearchDelay = false,
}: Parameters<EntitySelect<T>>[0]): ReturnType<EntitySelect<T>> => {
    const [query, setQuery] = useTextInputWithDelay((value) => search?.(value), '', 1, noSearchDelay ? 0 : undefined);
    const ref = useScrollThreshold<HTMLDivElement>(getMoreResults, { deps: [options?.length] });

    return (
        <div className={className}>
            {search && (
                <InputText
                    className="mu-dropdown-menu__search"
                    classModifiers={['full-width']}
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={placeholder}
                    contrast
                />
            )}
            <div ref={ref} className="mu-dropdown-menu__content--scroll">
                {options.map((entityId) => {
                    return (
                        <EntitySelectItem
                            key={entityId}
                            entityId={entityId}
                            useItem={useItem}
                            onSelect={onSelect}
                            getEntityName={getEntityName}
                            selected={!!values?.includes(entityId)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default EntitySelectInline;
