import React, { useState } from 'react';
import i18n from 'i18next';
import { Profile, ProfilesQueryParams, Questionnaire } from 'mushin-redux-store';
import SortBySelect from '../FiltersToolbar/Elements/SortBySelect';
import FiltersToolbar from '../FiltersToolbar/FiltersToolbar';
import { criteriaParamsFilter, groupsFilter, respondentStatusFilter } from '../FiltersToolbar/helpers';
import InputText from '../InputText/InputText';
import ActionBar from '../ActionBar/ActionBar';
import { useAppDispatch } from '../../Helpers/hooks';
import { usersExport } from '../../Helpers/excel/usersExport';
import { openModal } from '../../Redux/reducers/modalsSlice';
import { useTextInputWithDelay } from '../../Helpers/FormsHelpers/hooks';

export type RespondentsSearchParams = {
    sort?: string;
    name_search?: string;
    groups?: string[];
    criteria?: string[];
    status?: string;
};

type Props = React.PropsWithChildren<{
    searchParams: RespondentsSearchParams;
    setSearchParams: (searchParams: RespondentsSearchParams) => void;
    queryParams: ProfilesQueryParams;
    projectId?: string;
    selectedIds?: string[];
    questionnaire?: Questionnaire;
    getStatus: (user: Profile) => { mod: string; label: string; date?: string };
}>;

const RespondentToolbar: React.FC<Props> = ({
    searchParams,
    setSearchParams,
    queryParams,
    children,
    selectedIds = [],
    projectId,
    questionnaire,
    getStatus,
}) => {
    const [exportProgress, setExportProgress] = useState(-1);
    const [query, setQuery] = useTextInputWithDelay(
        (value) => setSearchParams({ ...searchParams, name_search: value }),
        searchParams.name_search,
    );

    const dispatch = useAppDispatch();

    const selectionActive = !!selectedIds?.length;

    return (
        <section className="mu-toolbar mu-toolbar--root">
            <div className="mu-toolbar__search">
                <InputText
                    classModifiers={['full-width', 'toolbar']}
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={i18n.t('components.filters.search')}
                />
            </div>
            <SortBySelect
                sort={searchParams.sort}
                setSort={(sort) => setSearchParams({ ...searchParams, sort })}
                options={[
                    { key: undefined, icon: 'Calendar', label: i18n.t('components.sort.mostRecent') },
                    { key: 'email', icon: 'ArrowDown', label: i18n.t('components.sort.alphabetic') },
                    { key: '-email', icon: 'ArrowUp', label: i18n.t('components.sort.reverseAlphabetic') },
                ]}
            />
            <FiltersToolbar
                filters={{
                    status: respondentStatusFilter(
                        searchParams.status,
                        (status) => setSearchParams({ ...searchParams, status }),
                        questionnaire,
                    ),
                    groups: groupsFilter(searchParams.groups, (groups) => setSearchParams({ ...searchParams, groups })),
                    criteria: criteriaParamsFilter(searchParams.criteria, (criteria) =>
                        setSearchParams({ ...searchParams, criteria }),
                    ),
                }}
                resetAll={() => setSearchParams({})}
            />
            <ActionBar
                items={[
                    {
                        icon: 'Stats',
                        label: i18n.t('criteria.items'),
                        handler: () => {
                            dispatch(openModal('ProfilesStats', { queryParams }));
                        },
                    },
                    {
                        icon: 'Export',
                        label: i18n.t('global.export'),
                        handler: () => {
                            dispatch(
                                usersExport(
                                    selectedIds,
                                    { ...searchParams, project_id: projectId },
                                    setExportProgress,
                                    getStatus,
                                ),
                            ).then(() => setExportProgress(-1));
                        },
                        hidden: !selectionActive,
                        progress: exportProgress,
                    },
                ]}
            />
            {children}
        </section>
    );
};

export default RespondentToolbar;
