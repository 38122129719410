import React, { useState } from 'react';
import {
    useCriteria,
    useCriteriaQuery,
    useCriterion,
    useCurrentOrganizationId,
    useCurrentProjectId,
} from 'mushin-redux-store';
import i18n from 'i18next';
import InputText from '../../../Components/InputText/InputText';
import { useTextInputWithDelay } from '../../../Helpers/FormsHelpers/hooks';
import { useScrollThreshold } from '../../../Helpers/effects';
import DropdownMenu from '../../../Components/DropdownMenu/DropdownMenu';
import MuIcon from '../../../Components/MuIcon';
import { classWithModifiers } from '../../../Helpers/css';
import TextIcon from '../../../Components/TextIcon/TextIcon';
import { useAppDispatch } from '../../../Helpers/hooks';
import { openModal } from '../../../Redux/reducers/modalsSlice';
import CriteriaTranslation from '../../../Components/Translation/CriteriaTranslation';

type Props = {
    onSelectCriterion: (id: string) => void;
    selectedCriterion?: string;
    toggleClassName: string;
    toggleLabel: React.ReactNode;
    disabled?: boolean;
};

const CriteriaFilterSelection: React.FC<Props> = ({
    onSelectCriterion,
    selectedCriterion,
    disabled,
    toggleClassName,
    toggleLabel,
}) => {
    const [organizationId] = useCurrentOrganizationId();
    const [projectId] = useCurrentProjectId();
    const criteriaQuery = useCriteriaQuery({ organization_id: organizationId, project_id: projectId }, true);
    const [query, setQuery] = useTextInputWithDelay((value) =>
        criteriaQuery.setParams({
            organization_id: organizationId,
            project_id: projectId,
            name_search: value || undefined,
        }),
    );

    const dispatch = useAppDispatch();

    const criteriaList = useCriteria(criteriaQuery.results || []);
    const options = criteriaList?.map((it) => ({ label: it?.name, options: it?.values?.map(() => it.id) }));

    const criterion = useCriterion(selectedCriterion);

    const [isMenuOpen, setMenuOpen] = useState(false);

    const ref = useScrollThreshold<HTMLDivElement>(criteriaQuery.getMoreResults, { deps: [options?.length] });

    const onSelect = (id: string) => {
        onSelectCriterion(id);
        setMenuOpen(false);
    };

    const handleCreateCriterion = () => {
        setMenuOpen(false);
        dispatch(openModal('CreateCriterion'));
    };

    return (
        <>
            <DropdownMenu
                align="center"
                isOpen={isMenuOpen}
                setIsOpen={setMenuOpen}
                toggle={
                    <>
                        {toggleLabel}
                        {criterion && (
                            <div className="mu-question-editor__filter-value">
                                {!disabled && (
                                    <button
                                        type="button"
                                        className="mu-question-editor__remove-filter-icon"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onSelect('');
                                        }}
                                    >
                                        <MuIcon svgName="Close" />
                                    </button>
                                )}
                                <CriteriaTranslation criterion={criterion} disabledSwitch />
                            </div>
                        )}
                    </>
                }
                toggleClassName={toggleClassName}
                disabled={disabled}
            >
                <div className="mu-filters-toolbar__inline-select">
                    <InputText
                        className="mu-dropdown-menu__search"
                        classModifiers={['full-width']}
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                        placeholder={i18n.t<string>('global.select')}
                        contrast
                    />
                    <div ref={ref} className="mu-dropdown-menu__content--scroll">
                        {criteriaList?.filter(Boolean)?.map((c) => {
                            if (!c) return null;
                            return (
                                <li
                                    key={c.id}
                                    onClick={() => onSelect(c.id)}
                                    className={classWithModifiers('mu-dropdown-menu__button-item', {
                                        selected: c.id === selectedCriterion,
                                    })}
                                >
                                    <button type="button">
                                        <CriteriaTranslation criterion={c} center />
                                    </button>
                                </li>
                            );
                        })}
                        <li className="mu-dropdown-menu__button-item" onClick={handleCreateCriterion}>
                            <button type="button">
                                <TextIcon
                                    className="mu-question-editor__add-criteria"
                                    icon="Plus"
                                    label={i18n.t('criteria.create')}
                                />
                            </button>
                        </li>
                    </div>
                </div>
            </DropdownMenu>
        </>
    );
};

export default CriteriaFilterSelection;
