import React from 'react';
import i18n from 'i18next';
import InputText from '../../../Components/InputText/InputText';
import { openModal } from '../../../Redux/reducers/modalsSlice';
import { useAppDispatch } from '../../../Helpers/hooks';
import MuButton from '../../../Components/MuButton';
import Client from '../../../Services/Client';
import { useTextInputWithDelay } from '../../../Helpers/FormsHelpers/hooks';

export type MembersSearchParams = {
    name_search?: string;
};

type Props = {
    searchParams: MembersSearchParams;
    setSearchParams: (searchParams: MembersSearchParams) => void;
};

const CriteriaToolbar: React.FC<Props> = ({ searchParams, setSearchParams }) => {
    // TODO remove or rewrite criteria import functionality
    // const importInProgress = useAppSelector(
    //     (state) => state.appNotifications.some((item) => item.id === IMPORT_CRITERIA_ACTION_ID)
    // );
    const [query, setQuery] = useTextInputWithDelay((value) =>
        setSearchParams({ ...searchParams, name_search: value }),
    );

    const dispatch = useAppDispatch();

    return (
        <section className="mu-toolbar mu-toolbar--standalone mu-toolbar--root">
            <div className="mu-toolbar__search">
                <InputText
                    classModifiers={['full-width', 'toolbar']}
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={i18n.t('components.filters.search')}
                />
            </div>
            {Client.isAllowedToEditOrganization() && (
                <MuButton
                    classModifiers="toolbar"
                    handleClick={() => dispatch(openModal('CreateCriterion'))}
                    icon="Plus"
                    label={i18n.t('criteria.add')}
                />
            )}
            {/* Client.isAllowedToEditOrganization() && (
                <label
                    htmlFor="import-criteria-input"
                    className={classWithModifiers('mu-btn', {toolbar: true})}
                >
                    <input
                        id="import-criteria-input"
                        type="file"
                        style={{display: 'none'}}
                        onChange={async (e) => {
                            if (e.target.files?.length) {
                                const criteriaImporter = new CriteriaImporter();
                                await criteriaImporter.init(e.target.files[0], true);
                                dispatch(openModal('ImportCriteria', { criteriaImporter }));
                            }
                        }}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        disabled={importInProgress}
                    />
                    <MuIcon
                        className="mu-btn__left-icon"
                        svgName="Import"
                    />
                    {i18n.t('criteria.import')}
                </label>
            ) */}
        </section>
    );
};

export default CriteriaToolbar;
