import React, { useState } from 'react';
import { useCriteriaQuery, useCurrentOrganizationId, useCurrentProjectId } from 'mushin-redux-store';
import i18n from 'i18next';
import MuButton from '../../../MuButton';
import { useTextInputWithDelay } from '../../../../Helpers/FormsHelpers/hooks';
import InputText from '../../../InputText/InputText';
import { useScrollThreshold } from '../../../../Helpers/effects';
import CriterionDropdownPanelItem from './CriteriaDropdownPanelItem';

export type CriteriaSelectedValues = { id: string; values: string[] }[];

type Props = {
    criteria: CriteriaSelectedValues;
    setCriteria: (criteria: CriteriaSelectedValues) => void;
};

const CriterionDropdownPanel: React.FC<Props> = ({ criteria, setCriteria }) => {
    const [organizationId] = useCurrentOrganizationId();
    const [projectId] = useCurrentProjectId();
    const criteriaQuery = useCriteriaQuery({ organization_id: organizationId, project_id: projectId }, true);
    const [query, setQuery] = useTextInputWithDelay((value) =>
        criteriaQuery.setParams({
            organization_id: organizationId,
            project_id: projectId,
            name_search: value || undefined,
        }),
    );

    const [selectedCriteria, setSelectedCriteria] = useState<CriteriaSelectedValues>(criteria || []);

    const ref = useScrollThreshold<HTMLDivElement>(criteriaQuery.getMoreResults, { deps: [criteria?.length] });

    const handleSubmitUsers = () => {
        setCriteria(selectedCriteria);
    };

    const onSelectMultiple = (id: string, value: string) => {
        const newSelection = [...selectedCriteria];
        const index = selectedCriteria.findIndex((c) => c.id === id);
        if (index > -1) {
            const values = [...selectedCriteria[index].values];
            const valueIndex = values.indexOf(value);
            if (valueIndex > -1) {
                values.splice(valueIndex, 1);
            } else {
                values.push(value);
            }
            if (values.length) {
                newSelection[index] = { id, values };
            } else {
                newSelection.splice(index, 1);
            }
        } else {
            newSelection.push({ id, values: [value] });
        }
        setSelectedCriteria(newSelection);
    };

    return (
        <>
            <div className="mu-filters-toolbar__inline-select">
                <InputText
                    className="mu-dropdown-menu__search"
                    classModifiers={['full-width']}
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={i18n.t<string>('global.select')}
                    contrast
                />
                <div ref={ref} className="mu-dropdown-menu__content--scroll">
                    {criteriaQuery.results?.map((criterionId) => {
                        const selection = selectedCriteria.find((c) => c.id === criterionId);

                        return (
                            <CriterionDropdownPanelItem
                                key={criterionId}
                                criterionId={criterionId}
                                selectedValues={selection?.values || []}
                                selectValue={(slug) => {
                                    onSelectMultiple(criterionId, slug);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <MuButton
                className="mu-filters-toolbar__panel-submit"
                handleClick={handleSubmitUsers}
                label={i18n.t('components.filters.dropdown.apply')}
            />
        </>
    );
};

export default CriterionDropdownPanel;
